<!--
 * @Description: Excel导入
 * @Author: 琢磨先生
 * @Date: 2023-12-20 12:34:59
 * @LastEditors: 琢磨先生
 * @LastEditTime: 2023-12-20 12:57:23
-->
<template>
  <div style="padding: 0 20px">
    <el-button
      icon="UploadFilled"
      @click="onOpen"
      v-power="'admin/v1/company/qianyue/excel_import'"
      >Excel导入</el-button
    >
    <el-dialog
      title="导入费用数据"
      v-model="dialogVisible"
      width="700px"
      draggable
      :close-on-click-modal="false"
      :close-on-press-escape="false"
    >
      <el-radio-group v-model="form.way">
        <el-radio :label="0">累加</el-radio>
        <el-radio :label="1">注销(删除)</el-radio>
        <el-radio :label="2">覆盖(清空后添加)</el-radio>
      </el-radio-group>
      <el-alert type="info" :closable="false">
        <div class="flex-row item-center">
          请按格式编辑Excel文件后上传、只支持xlsx文件。
          <el-link type="primary" :underline="false" @click="onDownTemplate"
            >下载</el-link
          >文件格式模板
        </div>
      </el-alert>
      <div style="flex-grow: 1">
        <el-upload
          ref="uploadRef"
          class="upload-demo"
          :auto-upload="false"
          :http-request="upload"
          accept=".xls, .xlsx"
          :limit="1"
          drag
          action
        >
          <el-icon class="el-icon--upload">
            <upload-filled />
          </el-icon>
          <div class="el-upload__text">
            拖拽到此或者
            <em>点击选择</em>
          </div>
          <template #tip>
            <div class="el-upload__tip">请选择xlsx文件</div>
          </template>
        </el-upload>
      </div>
      <template #footer>
        <el-button type="primary" @click="excelImport" :loading="uploading"
          >立即导入</el-button
        >
      </template>
    </el-dialog>
  </div>
</template>

<script>
import fileDownload from "js-file-download";

export default {
  data() {
    return {
      dialogVisible: false,
      //   downloading: false,
      uploading: false,
      form: {
        way: 0,
      },
      rules: {},
      tempForm: {
        item_ids: [],
        //下载模板选项选择的订单类型，-1：全部
        order_type: -1,
      },
    };
  },
  props: ["expenseList"],
  emits: ["success"],
  created() {},
  methods: {
    /**
     * 确定下载模板
     */
    onDownTemplate() {
      //   this.downloading = true;
      this.$http
        .get("admin/v1/company/qianyue/excel_temp", {
          responseType: "blob",
        })
        .then((res) => {
          fileDownload(res, "签约公司模板.xlsx");
          //   this.tempDialogVisible = false;
        })
        .finally(() => {
          //   this.downloading = false;
        });
    },
    /**
     *
     */
    onOpen() {
      this.dialogVisible = true;
    },

    /**
     * 从excel导入房东
     */
    excelImport() {
      this.$refs.uploadRef.submit();
    },

    /**
     * 上传文件导入
     */
    upload(ctx) {
      this.uploading = true;
      var formData = new FormData();
      formData.append("file", ctx.file);
      this.$http
        .post(`admin/v1/company/qianyue/excel_import?way=${this.form.way}`, formData)
        .then((res) => {
          if (res.code == 0) {
            this.dialogVisible = false;
            this.$message({
              type: "success",
              message: res.msg,
            });
            this.$emit("success");
            this.$refs.uploadRef.clearFiles();
          }
        })
        .finally(() => {
          this.uploading = false;
        });
    },
  },
};
</script>

<style scoped></style>
